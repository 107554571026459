<template>
  <div>
    <a-button type="primary" @click="createTicket"> <a-icon type="plus" /> {{ $t('message.NewTicket') }} </a-button>
    <a-table :columns="columns" :data-source="data" class="mt-3">
      <span slot="subject" slot-scope="subject, record">
        <a-badge :status="record.priority == 0 ? 'error' : 'success'" />
        {{ subject }}
      </span>
      <span slot="status" slot-scope="status">
        <a-tag
          :color="STATUS_COLOR[status]"
        >
          {{ STATUS_TEXT[status].toUpperCase() }}
        </a-tag>
      </span>
      <span slot="category" slot-scope="category">
        {{ CATEGORY_TEXT[category].toUpperCase() }}
      </span>
      <div slot='action' slot-scope="text, record">
        <a-button class="fa fa-eye" shape="circle" icon="eye" v-on:click="viewTicket(record)" />
      </div>
    </a-table>
    <a-drawer
      :title="drawerStatus == 'edit' ? $t('message.EditTicket') : $t('message.CreateTicket')"
      :width="720"
      :visible="drawerVisible"
      :body-style="{ paddingBottom: '80px', paddingRight: '50px' }"
      @close="onClose"
    >
      <a-form :form="form" layout="vertical" @submit="submitTicket" hide-required-mark>
        <a-row :gutter="16">
          <a-col :span="16" :style="{ height: '78vh' }">
            <a-col :span="24">
              <a-form-item :label="$t('message.Subject')" class="mb-3">
                <a-input
                  v-model="initTargetTicket.subject"
                  :disabled="disableData"
                  :placeholder="$t('message.PleaseEnterSubject')"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item :label="$t('message.Description')" class="mb-3">
                <a-textarea
                  v-model="initTargetTicket.description"
                  :rows="4"
                  :disabled="disableData"
                  :placeholder="$t('message.PleaseEnterDescription')"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24" v-if="drawerStatus == 'edit'">
              {{ $t('message.Comments') }}
              <div :style="{ maxHeight: '250px', overflowY: 'scroll', padding: '10px' }">
                <div>
                  <a-textarea
                    :style="{ marginTop: '15px', width: '100%' }"
                    :rows="4"
                    :placeholder="$t('message.PleaseEnterComment')"
                    @change="handleTargetComment"
                    :value="targetComment"
                  />
                  <div :style="{ width: '100%', display: 'flex', justifyContent: 'flex-end' }">
                    <a-button type="primary" v-on:click="saveComment">
                      Save
                    </a-button>
                  </div>
                </div>
                <template v-for="comment in comments">
                  <div v-if="selectedCommentId != comment.id" :key="comment.id" :style="{ marginTop: '10px', backgroundColor: '#efefef', padding: '5px', borderRadius: '5px' }">
                    <p><b>{{ comment.commenter_user.email }}</b></p>
                    <p>{{ comment.comment }}</p>
                    <p :style="{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '0px', fontSize: '10px' }">Updated: {{ getDateFormat(comment.modified) }}</p>
                  </div>
                  <div :key="comment.id" v-if="selectedCommentId == comment.id">
                    <p
                      :style="{ marginTop: '15px', width: '100%' }"
                      :rows="4"                      
                    >
                    {{ comment.comment }}
                    </p>
                    <!-- <div :style="{ width: '100%', display: 'flex', justifyContent: 'flex-end' }">
                      <a-button type="primary" v-on:click="updateComment(comment.id)">
                        Update
                      </a-button>
                      <a-button type="default" v-on:click="deleteComment(comment.id)" :style="{ marginLeft: '10px' }">
                        Delete
                      </a-button>
                      <a-button v-on:click="cancelComment" :style="{ marginLeft: '10px' }">
                        Cancel
                      </a-button>
                    </div> -->
                  </div>
                </template>
              </div>
            </a-col>
          </a-col>
          <a-col :span="8">
            <a-col :span="24">
              <a-form-item :label="$t('message.Status')" class="mb-3">
                <a-select
                  v-model="initTargetTicket.status"
                  :placeholder="$t('message.PleaseSelectStatus')"
                  disabled
                >
                  <a-select-option :value="0">
                    {{ $t('message.New') }}
                  </a-select-option>
                  <a-select-option :value="1">
                    {{ $t('message.Feedback') }}
                  </a-select-option>
                  <a-select-option :value="3">
                    {{ $t('message.InProgress') }}
                  </a-select-option>
                  <a-select-option :value="4">
                    {{ $t('message.Resolved') }}
                  </a-select-option>
                  <a-select-option :value="5">
                    {{ $t('message.Closed') }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item :label="$t('message.Priority')" class="mb-3">
                <a-select
                  :disabled="disableData"
                  v-model="initTargetTicket.priority"
                  :placeholder="$t('message.PleaseChoosePriority')"
                >
                  <a-select-option :value="0">
                    {{ $t('message.High') }}
                  </a-select-option>
                  <a-select-option :value="1">
                    {{ $t('message.Low') }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item :label="$t('message.Category')" class="mb-3">
                <a-select
                  v-model="initTargetTicket.category"
                  :placeholder="$t('message.PleaseSelectCategory')"
                  :disabled="disableData"
                >
                  <a-select-option :value="0">
                    {{ $t('message.Billing') }}
                  </a-select-option>
                  <a-select-option :value="1">
                   {{ $t('message.AddPort') }}
                  </a-select-option>
                  <a-select-option :value="2">
                    {{ $t('message.Other') }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item :label="$t('message.Assignee')" class="mb-3" v-if="initTargetTicket.assignee_user">
                <p>{{ initTargetTicket.assignee_user.email }}</p>
              </a-form-item>
            </a-col>
          </a-col>
        </a-row>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button v-if="!disableData" :style="{ marginRight: '8px' }" @click="onClose">
            Cancel
          </a-button>
          <a-button v-if="!disableData" type="primary" html-type="submit" :loading="savingChanges">
            Submit
          </a-button>
        </div>
      </a-form>
    </a-drawer>
  </div>
</template>

<script>
import axios from "@/plugins/axios.js"
import {EventBus, Events} from "@/plugins/event-bus.js";


const STATUS_TEXT = {
  '0': 'New',
  '1': 'Feedback',
  '3': 'In Progress',
  '4': 'Resolved',
  '5': 'Closed',
};

const STATUS_COLOR = {
  '0': 'pink',
  '1': 'yellow',
  '3': 'cyan',
  '4': 'geekblue',
  '5': 'lime',
};

const CATEGORY_TEXT = {
  '0': 'Billing',
  '1': 'Add Port',
  '2': 'Other',
};

const columns = [
  {
    dataIndex: 'subject',
    key: 'subject',
    title: 'Subject',
    scopedSlots: { customRender: 'subject' },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    width: 150,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    scopedSlots: { customRender: 'category' },
    width: 150,
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: 150,
  },
];

export default {
  name: "TicketsIndex",
  components: {
  },
  data() {
    return {
      form: this.$form.createForm(this),
      drawerVisible: false,
      data: [],
      selectedTicketId: '',
      comments: [],
      targetComment: '',
      selectedCommentId: '',
      columns,
      STATUS_TEXT,
      STATUS_COLOR,
      CATEGORY_TEXT,
      initTargetTicket: {
        subject: '',
        description: '',
        status: 0,
        priority: 0,
        category: 0,
        assignee: 0,
        id: 0,
      },
      drawerStatus: 'create',
      users: [],
      savingChanges: false,
    };
  },
  computed: {
    owner() {
      if (
        this.$store.state.security.auth &&
        this.$store.state.security.auth.authenticated
      ) {
        return this.$store.state.security.auth.subject;
      }
      return '';
    },
  },
  beforeDestroy() {
    EventBus.$off(Events.DOWNLOAD_CERTIFICATE);
  },
  methods: {
    getTickets() {
      axios.get('ticket/tickets/').then(response => {
        this.data = response.data;
      }).catch((error) => {
        console.log(error);
      });
    },
    getUsers() {
      axios.get('ticket/users/').then(response => {
        this.users = response.data;
        this.initTargetTicket.assignee = response.data[0].id;
      }).catch((error) => {
        console.log(error);
      });
    },
    getComments(id) {
      axios.get(`ticket/tickets/${id}/comments/`).then(response => {
        this.comments = response.data;
        this.drawerStatus = 'edit';
        this.drawerVisible = true;
      }).catch((error) => {
        console.log(error);
      });
    },
    createTicket() {
      this.disableData = false;
      this.drawerStatus = 'create';
      this.drawerVisible = true;
      this.comments = [];
      this.selectedCommentId = 0;
      this.selectedTicketId = '';
    },
    onClose() {
      this.drawerVisible = false;
      this.targetComment = '';
      this.selectedCommentId = '';
      this.initTargetTicket = {
        subject: '',
        description: '',
        status: 0,
        priority: 0,
        category: 0,
        assignee: this.users[0].id,
        id: 0,
        assignee_user: 0,
      };
    },
    viewTicket(record) {
      let { assignee, assignee_user, subject, description, status, priority, category, id } = record;
      this.initTargetTicket =  { assignee, assignee_user, subject, description, status, priority, category, id };
      this.selectedTicketId = record.id;
      this.getComments(record.id);
      this.disableData = true;
    },
    submitTicket(e) {
      // this.drawerVisible = false;
      e.preventDefault();
      let values = this.initTargetTicket;
      if (values.subject == '') {
          this.notifyError(this.$t('message.SubjectFieldEmpty', {on: this.$t('domain.DomainName')}));
          return;
      }
      if (values.description == '') {
          this.notifyError(this.$t('message.DescriptionFieldEmpty', {on: this.$t('domain.DomainName')}));
          return;
      }
      this.savingChanges = true;
      values.category = Number(values.category)
      values.priority = Number(values.priority)
      values.status = Number(values.status)
      values.owner = this.owner;
      values.assignee = null;
      if (this.drawerStatus == 'create') {
        axios.post('ticket/tickets/', values).then(() => {
          this.getTickets()
          this.drawerVisible = false;
          this.savingChanges = false;
        }).catch((error) => {
          console.log(error);
          this.drawerVisible = false;
          this.savingChanges = false;
          this.notifyError(this.$t('message.SomethingWentWrong', {on: this.$t('domain.DomainName')}));
        });
      } else if (this.drawerStatus == 'edit') {
        axios.patch(`ticket/tickets/${values.id}/`, values).then(() => {
          this.getTickets()
          this.drawerVisible = false;
          this.savingChanges = false;
        }).catch((error) => {
          console.log(error);
          this.drawerVisible = false;
          this.savingChanges = false;
          this.notifyError(this.$t('message.SomethingWentWrong', {on: this.$t('domain.DomainName')}));
        });
      }
    },
    cancel() {
      console.log('Click on No');
    },
    selectComment(id, comment) {
      this.selectedCommentId = id;
      this.targetComment = comment
    },
    handleTargetComment(e) {
      this.targetComment = e.target.value;
    },
    saveComment() {
      let data = {};
      data.comment = this.targetComment;
      data.commenter = this.owner
      data.ticket = this.selectedTicketId;
      if (this.targetComment == '') {
          this.notifyError(this.$t('message.CommentFieldEmpty', {on: this.$t('domain.DomainName')}));
          return;
      }
      axios.post(`ticket/tickets/${this.selectedTicketId}/comments/`, data).then(() => {
        this.getComments(this.selectedTicketId);
        this.targetComment = '';
        this.selectedCommentId = ''
      }).catch((error) => {
        console.log(error);
        this.notifyError(this.$t('message.SomethingWentWrong', {on: this.$t('domain.DomainName')}));
      });
    },
    updateComment(id) {
      let data = {};
      data.comment = this.targetComment;
      data.commenter = this.owner
      data.ticket = this.selectedTicketId;
      if (this.targetComment == '') {
          this.notifyError(this.$t('message.CommentFieldEmpty', {on: this.$t('domain.DomainName')}));
          return;
      }
      axios.patch(`ticket/tickets/${this.selectedTicketId}/comments/${id}/`, data).then(() => {
        this.getComments(this.selectedTicketId);
        this.targetComment = '';
        this.selectedCommentId = ''
      }).catch((error) => {
        console.log(error);
        this.notifyError(this.$t('message.SomethingWentWrong', {on: this.$t('domain.DomainName')}));
      });
    },
    deleteComment(id) {
      axios.delete(`ticket/tickets/${this.selectedTicketId}/comments/${id}/`).then(() => {
        this.getComments(this.selectedTicketId);
        this.targetComment = '';
        this.selectedCommentId = ''
      }).catch((error) => {
        console.log(error);
        this.notifyError(this.$t('message.SomethingWentWrong', {on: this.$t('domain.DomainName')}));
      });
    },
    creatNewComment() {
      this.selectedCommentId = 'new';
      this.targetComment = '';
    },
    cancelComment() {
      this.selectedCommentId = ''
      this.targetComment = '';
    },
    getDateFormat(date) {
      let d = new Date(date);
      let yyyy = d.getFullYear();
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let month = months[d.getMonth()];
      let dd = d.getDate();
      let hh = d.getHours();
      let mm = d.getMinutes();
      let ss = d.getSeconds();
      // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      // let day = days[d.getDay()];

      return `${hh}:${mm}:${ss} ${month} ${dd}, ${yyyy}`
    }
  },
  mounted() {
    this.getTickets();
    this.getUsers();
  },
}
</script>
